@use "@angular/material" as mat;
@use "./pbna-m3-theme" as m3-theme;

@include mat.core();

html {
  @include mat.core-theme(m3-theme.$light-theme);

  @include mat.button-theme(m3-theme.$light-theme);
  @include mat.checkbox-theme(m3-theme.$light-theme);
  @include mat.chips-theme(m3-theme.$light-theme);
  @include mat.dialog-theme(m3-theme.$light-theme);
  @include mat.expansion-theme(m3-theme.$light-theme);
  @include mat.form-field-theme(m3-theme.$light-theme);
  @include mat.icon-theme(m3-theme.$light-theme);
  @include mat.icon-button-theme(m3-theme.$light-theme);
  @include mat.input-theme(m3-theme.$light-theme);
  @include mat.paginator-theme(m3-theme.$light-theme);
  @include mat.progress-spinner-theme(m3-theme.$light-theme);
  @include mat.select-theme(m3-theme.$light-theme);
  @include mat.sort-theme(m3-theme.$light-theme);
  @include mat.table-theme(m3-theme.$light-theme);
  @include mat.tooltip-theme(m3-theme.$light-theme);

  --mdc-filled-button-label-text-weight: 700;
  --mdc-filled-button-container-shape: 0;
  --mdc-outlined-button-container-shape: 0;
  --mdc-outlined-button-label-text-weight: 700;
  --mat-expansion-container-shape: 0;
  --mdc-dialog-container-shape: 4px;
  --mdc-dialog-container-color: #fff;
  --mat-option-selected-state-layer-color: #{mat.get-theme-color(
    m3-theme.$light-theme,
    surface-container-highest
  )};

  button[mat-icon-button] {
    --mdc-icon-button-icon-color: #{mat.get-theme-color(
        m3-theme.$light-theme,
        primary
      )};
  }

  .secondary-button {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: secondary);
  }

  .tertiary-button {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: tertiary);
  }

  .secondary-checkbox {
    @include mat.checkbox-color(
      m3-theme.$light-theme,
      $color-variant: secondary
    );
  }

  .secondary-form-field {
    @include mat.form-field-color(
      m3-theme.$light-theme,
      $color-variant: secondary
    );
  }

  .secondary-icon {
    @include mat.icon-color(m3-theme.$light-theme, $color-variant: secondary);
  }

  .secondary-mat-option {
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{mat.get-theme-color(
        m3-theme.$light-theme,
        secondary
      )};
  }

  .secondary-progress-spinner {
    @include mat.progress-spinner-color(
      m3-theme.$light-theme,
      $color-variant: secondary
    );
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  mat-dialog-content {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .secondary-paginator {
    --mdc-outlined-text-field-focus-outline-color: #{mat.get-theme-color(
        m3-theme.$light-theme,
        secondary
      )};
  }
}
